import logo from "../assets/ConnectedLogo.png"
import { ResetEmailAdd } from "../api";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";



const ResetEmail = () => {
    const navigate = useNavigate();

    const signInSchema = yup.object().shape({
        email: yup
            .string()
            .email("Please provide a valid email")
            .required("Please provide a valid email"),
    });

    const initialValues = {
        email: "",
    };

    const { mutate, isLoading } = useMutation(ResetEmailAdd, {
        onSuccess: (data, variables, context) => {
            renderSuccessMessage(data.message);
            navigate(`/resetpassword`);
            localStorage.setItem('resetemail', variables.email);
        },
        onError: (error) => renderErrorMessage(

        ),

    });


    return (
        <div className="main-background">
            <div className="signup-container">
                <img className="logo-img" src={logo} alt="" />
            </div>
            <div className="container">
                <div className="sign-up-form">
                    <h2 className="sign-up-form-text">Reset Password</h2>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        onSubmit={(values) => mutate(values)}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="signup-label">Email Address</label>
                                    <input type="email"
                                        className=" sign-up-input"
                                        id="exampleInputEmail1" name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="text-red-500  text-[1.4rem]"
                                    />
                                </div>
                                <button type="submit" className=" sign-up-btn btn-primary" disabled={isLoading}>Reset Email</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )

}

export default ResetEmail;