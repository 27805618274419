import { useQuery } from "@tanstack/react-query";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { getMyLeaks } from "../api";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useState } from "react";
import jpeglevel from "../assets/greenjpeg.png"
import pdflevel from "../assets/Filetypes.png"
import bluepng from "../assets/bluepng.png"
import blackjpg from "../assets/blackjpg.png";
import { FaCaretSquareRight, FaFileArchive } from "react-icons/fa";
import ReactPaginate from "react-paginate";

const MyLeaks = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { user } = useProtectedRoutesContext()
    const { data } = useQuery({
        queryKey: ["getMyLeaks"],
        queryFn: () => getMyLeaks(),
        suspense: true,
    });

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const [selectedleak, setselectedleak] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    return (
        <>
            <div className="container">
                <div className="for-flex">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                </div>
                <div className="leaks-div mt-5">
                    <h3 className="my-leak-text">Leaks</h3>

                </div>
                {data?.data?.data?.map((leak, index) => (
                    <div className="the-leak-parent-div">
                        {/* <div className="toggle-left-container">
                            <BiEditAlt className="leak-extra"
                                onClick={() => {
                                    setselectedleak(leak);
                                    setIsEditModalOpen(true);
                                }}
                            />

                        </div> */}

                        {/* Dropdown Menu */}
                        <Link to={`/leaksdetails/${leak?.uuid}`}>
                            <h1 className="the-leak-header-text">{leak?.title}</h1></Link>
                        <h6 className="query-sub-description">{leak?.description}</h6>
                        {Array.isArray(leak?.tags) && leak.tags.length > 0 ? (
                            <div>
                                <h6 className="categories-text">Categories</h6>
                                <div className="category-display-div">
                                    {leak.tags.map((leaktag, index) => (
                                        <div key={index} className="cat-new-options-div">
                                            <h6 className="cat-new-options">{leaktag}</h6>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}

                        {Array.isArray(leak?.attachments) && leak.attachments.length > 0 ? (
                            <div>
                                <h6 className="categories-text">Attachments</h6>

                                <div key={index} className="file-format">
                                    {leak?.attachments.map((attachment, idx) => {
                                        const extension = attachment.split('.').pop().toLowerCase();

                                        return (
                                            <div key={idx} >
                                                {extension === "pdf" && (
                                                    <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                        <img className="tailwind" src={pdflevel} alt="PDF" />
                                                    </a>
                                                )}
                                                {extension === "jpeg" && (
                                                    <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                        <img className="tailwind" src={jpeglevel} alt="JPEG" />
                                                    </a>
                                                )}
                                                {extension === "jpg" && (
                                                    <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                        <img className="tailwind" src={blackjpg} alt="JPG" />
                                                    </a>
                                                )}
                                                {extension === "png" && (
                                                    <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                        <img className="tailwind" src={bluepng} alt="PNG" />
                                                    </a>
                                                )}
                                                {extension === "mp4" && (
                                                    <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                        <div className="video-play-icon">
                                                            <img className="tailwind" src={<FaCaretSquareRight className="tailwind" />} alt="Video" />

                                                        </div>
                                                    </a>
                                                )}
                                                {extension === "tmp" && (
                                                    <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                        <div className="video-play-icon1">
                                                            <FaFileArchive className="tailwind" /> {/* Icon for ZIP files */}
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        ) : null}

                        {/* <Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Leak Details" size="large">
                            <AddLeak myownleak={selectedleak} setIsOpen={setIsEditModalOpen} />
                        </Modal> */}

                    </div>
                ))}
                <ReactPaginate
                    previousLabel={'< Prev'}
                    previousClassName="previous-class"
                    nextLabel={'Next >'}
                    nextClassName="next-class"
                    pageCount={data?.data?.total / 10}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                />
            </div>
        </>
    )
}
export default MyLeaks;