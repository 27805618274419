import { useQuery } from "@tanstack/react-query";
import searchIcon from "../assets/search-normal(1).svg";
import { useState } from "react";
import { profileSearch } from "../api";
import UserInfo from "../modal/UserInfo";
import Modal from "../modal/Modal";
import { formatDistanceToNow, parseISO } from "date-fns";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";



const ProfileSearch = () => {
    const [selectedperson, setselectedperson] = useState(null);
    const [isAddQueryModalOpen2, setIsAddQueryModalOpen2] = useState(false);

    const [currentPage, setCurrentPage] = useState(1); //

    let storedObject = localStorage.getItem('user');
    let role = '';

    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        role = parsedObject?.role;


    } else {

    }

    const [searchQuery, setSearchQuery] = useState('');

    const { data: searchprofileresult } = useQuery({
        queryKey: ["profilesearch", searchQuery, currentPage],
        queryFn: () => profileSearch(searchQuery, role, currentPage),
        suspense: true,
    });



    // const handleSearch = () => {
    //     // Perform search when user clicks on image
    //     // setCurrentPage(1)
    //     refetch();
    // };


    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };



    return (
        <div>
            <Helmet>
                <title>Profile Search</title>
            </Helmet>
            <div className="container">
                <p className="my-queries-sub">Profile Search</p>
                <div className="account-container1">
                    <div className="profile-search-div">
                        <img src={searchIcon} alt="Search" />
                        <input placeholder="Search profiles by name, topic, outlet, job title, country"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className="row">
                            {searchprofileresult && searchprofileresult?.data?.data && searchprofileresult?.data?.data?.length > 0 ? (
                                searchprofileresult?.data?.data?.map((profileResult, index) => (
                                    <div className="col-lg-4" key={index}>
                                        <div className="the-found-result" onClick={() => {
                                            setselectedperson(profileResult);
                                            setIsAddQueryModalOpen2(true)

                                        }} >
                                            <div className="image-search-div">
                                                <img src={profileResult?.avatar} className="image-search" alt="" />
                                            </div>
                                            <h1 className="first_last1">Username: {profileResult?.username}</h1>
                                            <h1 className="first_last1">Role: {profileResult?.role}</h1>
                                            <h1 className="first_last1">{profileResult?.job_title}</h1>
                                            <h6 className="first_last1">Joined: {formatDistanceToNow(parseISO(profileResult?.created_at), { addSuffix: true, includeSeconds: false })}</h6>
                                        </div>
                                    </div>

                                ))
                            ) : (
                                <div className="no-result-yet-container">
                                    <p className="no-result-yet">No search results found.</p>
                                </div>
                            )}
                        </div>
                        <ReactPaginate
                            previousLabel={' Previous'}
                            previousClassName="previous-class"
                            nextLabel={'Next '}
                            nextClassName="next-class"
                            pageCount={searchprofileresult?.data?.total ? Math.ceil(searchprofileresult?.data?.total / searchprofileresult?.data?.per_page) : (0)}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'active-paginate'}
                            renderOnZeroPageCount={null}
                            breakLabel="..."
                        // pageClassName={'page-item-class'}
                        />
                    </div>
                </div>
            </div>
            <Modal isOpen={isAddQueryModalOpen2} setIsOpen={setIsAddQueryModalOpen2} title="Information Details" size="modal-normal">
                <UserInfo setIsOpen={setIsAddQueryModalOpen2} selectedperson={selectedperson?.uuid} role={role} userRole={selectedperson?.role} />
            </Modal>
        </div>
    )
}

export default ProfileSearch;
