import React, { useState } from "react";
import Modal from "../modal/Modal";
import details from "../assets/edit-2.svg"
import profile from "../assets/profileee.svg"
import sms from "../assets/sms1.svg"
import EditAccount from "../modal/EditAccount";
import { Helmet } from "react-helmet";

const AccountTab = () => {
    const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);

    return (
        <>
            <Helmet>
                <title>Account</title>
            </Helmet>
            <div className="">
                <div className="account-details-edit-container">
                    <h4 className="edit-account-details">Account Details</h4>
                    <p className="edit-text"><span className="edit-icon"><img src={details} alt="" onClick={() => setIsEditAccountModalOpen(true)} /></span> Edit </p>
                </div>
                <div className="account-details-edit-container-now">
                    <img src={profile} className="edit-account-icon" alt="" />
                    <h6 className="edit-account-name">Adenike Johnson</h6>
                </div>

                <div className="account-details-edit-container-now">
                    <img src={sms} className="edit-account-icon" alt="" />
                    <h6 className="edit-account-name">adenikejohn@gmail.com</h6>
                </div>
            </div>
            <Modal isOpen={isEditAccountModalOpen} setIsOpen={setIsEditAccountModalOpen} title="Edit Account Details" size="modal-normal">
                <EditAccount setIsOpen={setIsEditAccountModalOpen} />
            </Modal>
        </>
    )
}


export default AccountTab;