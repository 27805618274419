import { Link } from "react-router-dom";
import logo from "../assets/ConnectedLogo.png"
import { Helmet } from "react-helmet";

const AllSet = () => {
    return (
        <div>
            <Helmet>
                <title>All Set</title>
            </Helmet>
            <div className="main-background">
                <div className="signup-container">
                    <img className="logo-img" src={logo} alt="" />
                </div>
                <h1 className="verify-heading">You’re all set, Adenike!</h1>
                <p className="verify-heading-sub">Congratulations on completing your onboarding</p>
                <div className="save-and-continue-contain">
                    <Link to={"/login"}><button className="save-and-continue">Take me to the App</button></Link>
                </div>
            </div>
        </div>

    )

}

export default AllSet;