import circle from "../assets/tick-circle.svg"
import info from "../assets/info-circle.svg"
// import circleing from "../assets/info-circle.svg"
// import searchimg from "../assets/search-normal.svg"
import { useMutation, useQuery } from "@tanstack/react-query"
import { createAndUpdateProfile, getAssociatedOutlets, getTags } from "../api"
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions"
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { Formik } from "formik"
import Select from 'react-select'
import ProfileImageDropzone from "./ProfileImageDropzone";
import { Helmet } from "react-helmet";



const ProfileInfo = () => {
    const { setUser } = useProtectedRoutesContext();
    let storedObject = localStorage.getItem('user');
    let firstname = "";
    let username = "";
    let userEmail = "";
    let job_title = "";
    let country = "";
    let website_url = "";
    let bio = "";
    let avatar = "";
    let interests = [];
    let associated_outlets = [];



    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject?.first_name;
        job_title = parsedObject?.job_title;
        username = parsedObject?.username;
        country = parsedObject?.country;
        avatar = parsedObject?.avatar;
        bio = parsedObject?.bio || "";
        userEmail = parsedObject.email;
        website_url = parsedObject.website_url;
        interests = parsedObject?.interests?.map(interest => ({ label: interest, value: interest })) || [];
        associated_outlets = parsedObject.associated_outlets?.map(out => ({ label: out, value: out })) || [];
    } else {

    }



    const initialValues = {
        first_name: firstname,
        username: username,
        email: userEmail,
        job_title: job_title,
        country: country,
        website_url: website_url,
        interests: interests,
        associated_outlets: associated_outlets,
        bio: bio,
        avatar: avatar
    };


    const { data: gettags } = useQuery({
        queryKey: ["getTags"],
        queryFn: () => getTags(),
    });
    const { data: outlet } = useQuery({
        queryKey: ["getAssociatedOutlets"],
        queryFn: () => getAssociatedOutlets(),
    });



    const options = outlet?.data.map((option, index) => ({
        value: option?.name,
        label: option?.name,
    }));

    const options1 = gettags?.data.map((option, index) => ({
        value: option?.name,
        label: option?.name,
    }));

    const { mutate, isLoading } = useMutation(createAndUpdateProfile, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setUser(data?.data);
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });

    return (
        <div>
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <div className="container">
                <div className="good-greet-div">
                    <h2 className="good-greeting">Hello, {firstname}</h2>
                </div>
                {/* <p className="my-queries-sub">Profile</p> */}
                <div className="profile-info-container">
                    <p className="personal-info">Personal Info</p>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting }) => {

                            if (!values?.avatarPreview) {
                                delete values.avatar;
                            }
                            // Check if values.interests and values.media are arrays
                            const transformedTags = values.interests.map(interest => interest.value);
                            const transformedOutlets = values.associated_outlets.map(outlet => outlet.value);

                            const data = {
                                ...values,
                                associated_outlets: transformedOutlets,
                                interests: transformedTags,
                            };


                            mutate(data);  // Assuming mutate is your API call function

                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={(e) => {
                                e.preventDefault(); // Prevent default form submission
                                handleSubmit(e);
                            }}>
                                <ProfileImageDropzone setFieldValue={setFieldValue} avatarPerson={avatar} />
                                <div className="row g-3 align-items-center mt-5">
                                    <div className="col-lg-3">
                                        <label className="col-form-label job-title-header">Job Title</label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input type="text" className="form-control unfinished-input" placeholder="Chief Technical Officer, Revocube Tech"
                                            name="job_title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.job_title}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3 align-items-center mt-5">
                                    <div className="col-lg-3">
                                        <label className="col-form-label job-title-header">Country</label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input type="text" className="form-control unfinished-input"
                                            name="country"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.country}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3 align-items-center mt-5">
                                    <div className="col-lg-3">
                                        <label className="col-form-label job-title-header">Email</label>
                                    </div>

                                    <div className="col-lg-9">
                                        <p><span><img src={circle} alt="Check Email" /></span> {userEmail}</p>
                                        <div className="d-flex show-public-profile">
                                            {/* <input type="checkbox" className="" />
                                            <p> Show email on public profile  </p>
                                            <img src={info} alt="Check Email" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-3 align-items-center mt-5">
                                    <div className="col-lg-3">
                                        <label className="col-form-label job-title-header">Username</label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input type="text" className="form-control unfinished-input"
                                            name="username"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.username}
                                        />
                                    </div>
                                </div>
                                <div className="row g-3 align-items-center mt-5">
                                    <div className="col-lg-3">
                                        <label className="col-form-label job-title-header">Website URL</label>
                                    </div>
                                    <div className="col-lg-9">
                                        <input type="text" className="form-control unfinished-input"
                                            name="website_url"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.website_url}
                                        />
                                    </div>
                                </div>

                                <h4 className="my-topics-text">My Topics</h4>
                                <div className="divider-div"></div>
                                {/* <div className="form-group">
                                <div className="sign-up-input44 category-flex1">
                                    <img src={searchimg} alt="" />
                                    <input type="text" className="form-controller1" id="exampleInputPassword2" placeholder="Search for topics" />
                                </div>
                            </div> */}
                                <Select className="sign-up-input-textarea"
                                    value={values.interests}
                                    onChange={(value) => setFieldValue("interests", value)}
                                    options={options1}
                                    isMulti
                                />

                                <h4 className="my-topics-text">Outlets</h4>
                                <div className="divider-div"></div>
                                {/* <div className="form-group">
                                <div className="sign-up-input44 category-flex1">
                                    <img src={searchimg} alt="" />
                                    <input type="text" className="form-controller1" id="exampleInputPassword2" placeholder="Search for topics" />
                                </div>
                            </div> */}
                                <Select className="sign-up-input-textarea"
                                    value={values.associated_outlets}
                                    onChange={(value) => setFieldValue("associated_outlets", value)}
                                    options={options}
                                    isMulti
                                />

                                <h4 className="my-topics-text">My Bio</h4>
                                <div className="divider-div"></div>
                                <textarea className="sign-up-input-textarea-new"
                                    name="bio"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.bio}
                                />

                                <button type="submit" className=" sign-up-btn" disabled={isLoading}>Save Changes</button>
                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    )
}

export default ProfileInfo;