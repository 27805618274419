import { Link } from "react-router-dom";

const CheckCompleted = (setIsOpen1) => {


    return (
        <div className="container">
            <form className="flex flex-col">
                <div className="create-query-div">
                    <Link to={"/profileinfo"}><button type="submit" className="create-query-btn-one">Update Profile</button></Link>
                </div>
            </form>
        </div>
    )
}

export default CheckCompleted;