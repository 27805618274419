import { Helmet } from "react-helmet";

const DataPrivacyTab = () => {
    return (
        <div className="container">
            <Helmet>
                <title>Data Privacy</title>
            </Helmet>
            <h2 className="data-header">Data Privacy Tab</h2>
            <p className="we-keep-header">We keep your data private. View our privacy policy for  connected newsrooms.</p>
            <h2 className="data-header">Data Tracking</h2>
            <p className="we-keep-header">Access your data and learn about what data we keep.</p>
        </div>
    )
}

export default DataPrivacyTab;