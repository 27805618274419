/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import logo from "../assets/ConnectedLogo.png"
import notification from "../assets/notification-bing.svg"
import ladyperson from "../assets/person-lady.svg"
import ladyprofile from "../assets/dropdown-profile.svg"
import profileperson from "../assets/profile.svg"
import profilenotification from "../assets/notification-bing1.svg"
import bookmark1 from "../assets/bookmark1.svg"
import settings from "../assets/setting-2.svg"
// import cnlogo from "../assets/CN.svg"
import logout from "../assets/logout.svg"
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { useQuery } from "@tanstack/react-query";
import { getNotification } from "../api";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
// import ReactPaginate from "react-paginate";




const Navbar = () => {
    const { setUser, setToken } = useProtectedRoutesContext();
    const navigate = useNavigate();

    let storedObject = localStorage.getItem('user');

    let firstname = '';
    let lastname = '';
    let jobtitle = '';
    let avatar = '';



    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject?.first_name;
        lastname = parsedObject?.last_name;
        jobtitle = parsedObject?.job_title;
        avatar = parsedObject?.avatar;
    } else {

    }



    const { data } = useQuery({
        queryKey: ["getNotification"],
        queryFn: () => getNotification(),
    });

    // State for pagination
    const [pageNumber] = useState(0);
    const resultsPerPage = 5;
    const pagesVisited = pageNumber * resultsPerPage;

    // Function to handle page change

    return (
        <div className="">
            <nav className="navbar navbar-bcg navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand"><img src={logo} alt="" className="profile-logo" /></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="/queries">Queries<span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/myleaks">Leaks</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/profilesearch">Profile Search</a>
                            </li>

                        </ul>
                        <form className="form-inline my-2 my-lg-0">
                            {/* <img src={bookmark} alt="" className="mr-4" /> */}
                            {/* <img src={notification} alt="" className="mr-4" /> */}
                            <div className="nav-item dropdown">

                                <a className="" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={notification} alt="" className="" />
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div>
                                        <p className="section-title">Latest notifications</p>
                                        {data?.data?.data?.slice(pagesVisited, pagesVisited + resultsPerPage).map((notification, index) => (
                                            <div key={index} className="profile-person-flex-bg">
                                                <div className="profile-person-flex">
                                                    <Link className="dropdown-item notice" onClick={() => navigate(`/queries/${notification?.query_uuid}`, { replace: true })}>
                                                        {notification.message}
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}

                                    </div>

                                </div>
                            </div>

                            <div className="nav-item dropdown">

                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {avatar ? (<img src={avatar} alt="" className="profile-person-img ml-4" />) : (<img src={ladyperson} alt="" />)}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="dropdown-container-profile">
                                        <div className="person-container">
                                            {avatar ? (<img src={avatar} alt="" className="person-profile-display" />) : (<img src={ladyprofile} alt="" />)}
                                        </div>
                                        <h6 className="dropdown-container-profile-name">{firstname} {lastname}</h6>
                                        <p className="dropdown-container-profile-role">{jobtitle}</p>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={profileperson} alt="" />
                                        <Link to="profileinfo" className="dropdown-item">My Profile</Link>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={profilenotification} alt="" />
                                        <Link to="notification" className="dropdown-item">Notifications</Link>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={bookmark1} alt="" />
                                        <Link className="dropdown-item" >Archived Queries</Link>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <div className="profile-person-flex">
                                        <img src={settings} alt="" />
                                        <Link to="settings" className="dropdown-item">Settings</Link>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={logout} alt="" />
                                        <Link className="dropdown-item" href="#"
                                            onClick={() => {
                                                setToken(null);
                                                setUser(null);
                                                localStorage.clear();
                                                window.location.reload();
                                            }}
                                        >Logout</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    )

}

export default Navbar;