import { format, parseISO } from "date-fns";
import CustomDataTable from "../CustomDataTable";
// import resultimg from "../assets/EmptyState.svg"
import { Link } from "react-router-dom";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "@tanstack/react-query";
import { getArchivesQueries } from "../api";

const ArchivedQueries = () => {
    const [currentPage, setCurrentPage] = useState(1); //
    const resultsPerPage = 10;

    const { data: queries1 } = useQuery({
        queryKey: ["getArchivesQueries", currentPage],
        queryFn: () => getArchivesQueries(currentPage),
        suspense: true,
    });

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };

    const columns = [
        {
            name: 'Query Title',
            selector: row => row.title,
            compact: true,
            width: "280px",
            cell: (row) => (
                <Link className="cursor-pointer" to={`/queries/${row.uuid}`}>
                    {row.title}
                </Link>
            ),
        },
        {
            name: 'Tags',
            selector: (row) => row.tags.join(', '),
            compact: true,
        },
        {
            name: 'Deadline',
            // selector: (row) => format(parseISO(row.deadline), "PPPP"),
            selector: (row) => {
                const date = parseISO(row.deadline);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            width: "200px",
        },
        {
            name: 'Status',
            selector: row => row.status,
            compact: true,
        },
        {
            name: 'Pitch',
            selector: row => row.responses,
            compact: true,
            width: "120px"
        },

    ];




    return (
        <div className="container">
            <div className="custom-query-table">
                <CustomDataTable columns={columns}
                    data={queries1?.data?.data}

                />
            </div>
            <ReactPaginate
                previousLabel={' Previous'}
                previousClassName="previous-class"
                nextLabel={'Next '}
                nextClassName="next-class"
                pageCount={Math.ceil(queries1?.data?.total / resultsPerPage)}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active-paginate'}
                renderOnZeroPageCount={null}
                breakLabel="..."
            // pageClassName={'page-item-class'}
            />
        </div>
    )
}
export default ArchivedQueries;
