import logo from "../assets/ConnectedLogo.png";
import avatar from "../assets/avatar.png";
import circleing from "../assets/info-circle.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { createAndUpdateProfile, getAssociatedOutlets, getTags } from "../api";
import { Formik } from "formik";
import Select from 'react-select';
import { Helmet } from "react-helmet";


// import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { useNavigate } from "react-router-dom";

const ProfileOne = () => {
    const navigate = useNavigate();
    let storedObject = localStorage.getItem('user');

    let firstname = "";
    let lastname = "";
    let userEmail = "";

    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject && parsedObject.first_name ? parsedObject.first_name : ""; // Added additional check here
        lastname = parsedObject && parsedObject.last_name ? parsedObject.last_name : "";
        userEmail = parsedObject && parsedObject.email ? parsedObject.email : "";
    } else {

    }

    const initialValues = {
        first_name: firstname,
        last_name: lastname,
        username: "",
        email: userEmail,
        job_title: "",
        country: "",
        website_url: "",
        interests: [],
        associated_outlets: [],
    };





    const { data: gettags } = useQuery({
        queryKey: ["getTags"],
        queryFn: () => getTags(),
    });
    const { data: outlet } = useQuery({
        queryKey: ["getAssociatedOutlets"],
        queryFn: () => getAssociatedOutlets(),
    });


    const options = outlet?.data.map((option, index) => ({
        value: option?.name,
        label: option?.name,
    }));


    const options1 = gettags?.data?.map((option, index) => ({
        value: option.name,
        label: option.name,
    }));

    const { mutate } = useMutation(createAndUpdateProfile, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            navigate(`/allset`);

            // setUser(data?.data);
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });

    return (
        <div>
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <div className="main-background">
                <div className="signup-container">
                    <img className="logo-img" src={logo} alt="" />
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={({ email, firstname, lastname, ...values }, { setSubmitting }) => {


                        // Check if values.interests and values.media are arrays
                        const transformedTags = Array.isArray(values.interests) ? values.interests.map(interest => interest.value) : [];
                        const transformedMedia = Array.isArray(values.associated_outlets) ? values.associated_outlets.map(midi => midi.value) : [];

                        const data = {
                            ...values,
                            associated_outlets: transformedMedia,
                            interests: transformedTags,
                        };



                        mutate(data);  // Assuming mutate is your API call function

                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <div>
                            <div className="container">
                                <h1 className="verify-heading">Profile Information</h1>
                                <p className="verify-heading-sub">Your Profile information will help us personalise your experience</p>
                                <form onSubmit={(e) => {
                                    e.preventDefault(); // Prevent form from reloading
                                    handleSubmit(e); // Call Formik's handleSubmit
                                }}>
                                    <div className="sign-up-form1">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <AvatarDropzone />
                                            </div>

                                            <div className="col-lg-9">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="signup-label">First Name *</label>
                                                            <input type="text" className="sign-up-input "
                                                                name="first_name"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.first_name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="signup-label">Last Name *</label>
                                                            <input type="text" className="sign-up-input"
                                                                name="last_name"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.last_name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="signup-label">Email *</label>
                                                            <input type="email" className="sign-up-input"
                                                                name="email"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                                disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="signup-label">Job Title *</label>
                                                            <input type="text" className="sign-up-input"
                                                                name="job_title"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.job_title} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="signup-label">Country *</label>
                                                            <input type="text" className="sign-up-input"
                                                                name="country"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.country}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="signup-label">Website URL*</label>
                                                            <input type="text" className="sign-up-input"
                                                                name="website_url"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.website_url}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="signup-label">Username*</label>
                                                            <input type="text" className="sign-up-input"
                                                                name="username"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.username}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-5">
                                            <div className="form-group">
                                                <label className="signup-label">What topics do you cover? <span><img src={circleing} alt="" /></span></label>
                                                <Select type="text" className="sign-up-input-textareaas" placeholder="Your added topics will appear here"
                                                    value={values.interests}
                                                    onChange={(value) => setFieldValue("interests", value)}
                                                    options={options1}
                                                    isMulti
                                                />
                                            </div>


                                        </div>
                                        <div className="col-lg-12 mt-5">
                                            <div className="form-group">
                                                <label className="signup-label">What outlets are you associated with? <span><img src={circleing} alt="" /></span></label>
                                                {/* <div className="sign-up-input category-flex">
                                            <img src={searchimg} alt="" />
                                            <input type="text" className="form-controller" id="exampleInputPassword2" placeholder="Search for outlets" />
                                        </div> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">

                                                <Select className="sign-up-input-textarea"
                                                    value={values.associated_outlets}
                                                    onChange={(value) => setFieldValue("associated_outlets", value)}
                                                    options={options}
                                                    isMulti
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="save-and-continue-contain">
                                        <button type="submit" className="save-and-continue">Save and Continue</button>
                                    </div>
                                </form>
                            </div>

                        </div>

                    )}
                </Formik>

            </div>
        </div>
    )

}



const AvatarDropzone = () => {
    // const { setFieldValue, values } = useFormikContext();

    // const onDrop = useCallback(
    //     (acceptedFiles) => {
    //         setFieldValue(
    //             "profilePic",
    //             acceptedFiles.map((file) =>
    //                 Object.assign(file, {
    //                     preview: URL.createObjectURL(file),
    //                 })
    //             )[0]
    //         );
    //     },
    //     [setFieldValue]
    // );

    // const { getRootProps, getInputProps } = useDropzone({
    //     multiple: false,
    //     onDrop,
    // });

    return (
        <div
            className=""
        // {...getRootProps()}
        >
            <img
                src={avatar}
                alt="user-avatar"
                className="rounded-full w-[100px] aspect-square"
            />
        </div>
    );
};
export default ProfileOne;