import axios from "axios";
import { getFromStorage, renderErrorMessage, renderSuccessMessage } from "../assets/functions";


const authApi = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
    },
    withCredentials: true,
});

authApi.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error.response.status === 401) {
            renderErrorMessage(error.response.data.message).then(() => {
                localStorage.clear();
                window.location.reload();
            });
        } else if (error.response.status === 503) {
            renderSuccessMessage(error.response.data.message);
        } else {
            renderErrorMessage(error.response.data.message);
            throw new Error(error.response.data.message, {
                cause: error.response.status,
            });
        }
    }
);

export const signIn = (data) => {
    return api.post("journalist/login", data);
};
export const signUp = (data) => {
    return api.post("journalist/register", data);
};
export const InputPasswords = (data) => {

    return api.patch("journalist/create-profile", data);
};
export const getQuestions = () => {
    return api.get("questions");
};
export const ResetEmailAdd = (data) => {
    return api.post("journalist/reset-password", data);
};
// export const SecurityCheck = (data) => {
//     return api.post("journalist/security-question-check", data);
// };
export const VerifyOTP = (data) => {
    return api.post("journalist/verify-otp", data);
};
export const NewCredentials = (data) => {
    return api.patch("journalist/create-new-password", data);
};


const api = axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_LOCAL_BASEURL}`
            : `${process.env.REACT_APP_PROD_BASEURL}`,
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Credentials": true,
    },
    // withCredentials: true,
});


api.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${getFromStorage("token")}`;
    return config;
}, null);



api.interceptors.response.use(
    (response) => response.data,

    (error) => {
        if (error.response.status === 401) {
            renderErrorMessage(error.response.data.message).then(() => {
                localStorage.clear();
                window.location.reload();
            });
        } else if (error.response.status === 503) {
            renderSuccessMessage(error.response.data.message);
            throw new Error(error.response.data.message ?? "Account Created");
        } else {
            renderErrorMessage(error.response.data.message);
            throw new Error(error.response.data.message, {
                cause: error.response.status,
            });
        }
    }
);



export const createQuery = (data) => {
    return api.post("journalist/queries", data);
};

export const archiveQuery = (id) => {
    return api.patch(`/journalist/queries/${id}/archive`);
};

export const editQuery = ({ id, ...data }) => {
    return api.patch(`journalist/queries/${id}`, data);
};

export const getNotification = (page) => {
    return api.get(`journalist/notifications?page=${page}`);
};

export const createAndUpdateProfile = (data) => {
    return api.post("journalist/update-profile", { _method: "PATCH", ...data }, { headers: { "Content-Type": "multipart/form-data" } });
};

export const getTags = () => {
    return api.get("tags");
};

export const getAssociatedOutlets = () => {
    return api.get("associated-outlets");
};

export const getQueryDetails = (id, page) => {
    return api.get(`/journalist/queries/${id}?page=${page}`);
};


export const getAllQueries = (searchQuery, page) => {
    return api.get(`journalist/queries?q=${searchQuery}&page=${page}`);
};

export const getArchivesQueries = (page) => {
    return api.get(`journalist/archives?page=${page}`);
};


// export const QueryResponse = (id) => {
//     return api.get(`/journalist/queries/${id}/pitch/:pitchId`);
// };
export const profileSearch = (searchParams, role, page) => {
    return api.get(`profile-search?q=${searchParams}&role=${role}&page=${page}`);
};


export const getUserProfile = (id, role, userRole) => {
    return api.get(`profile-search/${id}?role=${role}&userRole=${userRole}`);
};

export const readNotification = (notificationId) => {

    return api.patch(`journalist/notifications/${notificationId}`);

};


export const ReplyPitch = ({ queryId, pitchId, replyText }) => {
    return api.post(`journalist/queries/${queryId}/pitch/${pitchId}/reply`, { reply: replyText });
};
export const changePassword = (data) => {
    return api.patch("journalist/change-password", data);
};
export const getMyLeaks = () => {
    return api.get(`journalist/leaks`);
};
export const getMyLeakDetails = (id, page) => {
    return api.get(`journalist/leaks/${id}?page=${page}`);
};
export const ReplyLeak = ({ leakId, ...data }) => {
    return api.post(`journalist/leaks/${leakId}/reply`, data);
};
export const PostLeakReply = ({ leakId, ...data }) => {
    return api.post(`journalist/leaks/${leakId}/reply`, data);
};
