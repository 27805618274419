import logo from "../assets/ConnectedLogo.png";
import journalist from "../assets/journa.svg";
import source from "../assets/bigflex.svg";
import { Helmet } from "react-helmet";


// import * as yup from "yup";




const WhoYouAre = () => {



    return (
        <div>
            <Helmet>
                <title>Connected Newsroom</title>
            </Helmet>
            <div className="main-background">
                <div className="signup-container">
                    <img className="logo-img" src={logo} alt="" />
                </div>
                <div className="container">
                    <h1 className="verify-heading">Let us Know Who You Are</h1>
                    <div className="who-flex">
                        <div>
                            <div className="choose-img">
                                <img src={journalist} alt="" />
                            </div>
                            <a href="google.com" className="who-link"><p>I’m a Journalist</p></a>
                        </div>
                        <div>
                            <div className="choose-img">
                                <img src={source} alt="" />
                            </div>
                            <a href="google.com" className="who-link"><p>I’m a Source</p></a>
                        </div>
                    </div>
                    <div className="create">
                        <button type="submit" className="sign-up-btn1">Create my Account</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default WhoYouAre;