import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError();


    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <Helmet>
                <title>404 Page Not Found</title>
            </Helmet>
            <div id="error-page" className="text-center p-5 bg-white shadow-md rounded-md">
                <h1 className="text-6xl font-bold text-red-500 mb-4">Oops!</h1>
                <p className="text-xl text-gray-700 mb-4">Sorry, Page not found</p>

                <Link to="/queries" className="text-blue-500 hover:text-blue-700 text-lg">
                    Go back to Home
                </Link>
            </div>
        </div>
    );
}
