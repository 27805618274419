import { useMutation, useQuery } from "@tanstack/react-query";
import { getNotification, readNotification } from "../api";
import { Link } from "react-router-dom";
import { renderErrorMessage } from "../assets/functions";
import { parseISO, formatDistanceToNow } from 'date-fns';
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";

const Notifications = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const { data } = useQuery({
        queryKey: ["getNotification", currentPage],
        queryFn: () => getNotification(currentPage),
        suspense: true,
    });

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return 'today';
        } else if (date.toDateString() === yesterday.toDateString()) {
            return 'yesterday';
        } else {
            return 'older';
        }
    };

    const resultsPerPage = 10;

    const todayNotifications = data?.data?.data?.filter(
        (notification) => formatDate(notification?.created_at) === 'today'
    );

    const yesterdayNotifications = data?.data?.data?.filter(
        (notification) => formatDate(notification?.created_at) === 'yesterday'
    );

    const olderNotifications = data?.data?.data?.filter(
        (notification) => formatDate(notification?.created_at) === 'older'
    );

    const { mutate } = useMutation(readNotification, {
        onSuccess: (data) => {
            // Handle success
        },
        onError: (error) => renderErrorMessage(
            // Handle error
        ),
    });

    const noNotifications =
        todayNotifications?.length === 0 &&
        yesterdayNotifications?.length === 0 &&
        olderNotifications?.length === 0;

    return (
        <>
            <div>
                <Helmet>
                    <title>Notifications</title>
                </Helmet>
                <div className="container">
                    <p className="my-queries-sub">Notifications</p>
                    <div className="account-container1">
                        {noNotifications ? (
                            <div className="no-result-container">
                                <div className="no-result-found">
                                    <h4 className="no-result-found-text">No Notifications</h4>
                                </div>
                            </div>
                        ) : (
                            <div className="">
                                {todayNotifications?.length > 0 && (
                                    <div>
                                        <h2 className="notify-past">Today</h2>
                                        {todayNotifications?.map((notification, index) => (
                                            <div className={notification.is_read ? "learn-more-container1 mb-3" : "learn-more-container mb-3"} key={index}>
                                                <div className="">
                                                    <h3 className="learn-more-text">{notification?.message}</h3>
                                                    <h3 className="learn-more-text33 mt-2">{formatDistanceToNow(parseISO(notification?.created_at), { addSuffix: true, includeSeconds: false })}</h3>
                                                </div>
                                                <Link to={`/queries/${notification?.query_uuid}`}>
                                                    <p className="learn-more-link" onClick={() => {
                                                        mutate(notification?.uuid)
                                                    }}>View</p>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {yesterdayNotifications?.length > 0 && (
                                    <div>
                                        <h2 className="notify-past mt-5">Yesterday</h2>
                                        {yesterdayNotifications?.map((notification, index) => (
                                            <div className={notification.is_read ? "learn-more-container1 mb-3" : "learn-more-container mb-3"} key={index}>
                                                <div className="">
                                                    <h3 className="learn-more-text">{notification?.message}</h3>
                                                    <h3 className="learn-more-text33 mt-2">{formatDistanceToNow(parseISO(notification?.created_at), { addSuffix: true, includeSeconds: false })}</h3>
                                                </div>
                                                <Link to={`/queries/${notification?.query_uuid}`}>
                                                    <p className="learn-more-link" onClick={() => {
                                                        mutate(notification?.uuid)
                                                    }}>View</p>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {olderNotifications?.length > 0 && (
                                    <div>
                                        <h2 className="notify-past mt-5">Older Notifications</h2>
                                        {olderNotifications?.map((notification, index) => (
                                            <div className={notification.is_read ? "learn-more-container1 mb-3" : "learn-more-container mb-3"} key={index}>
                                                <div className="">
                                                    <h3 className="learn-more-text">{notification?.message}</h3>
                                                    <h3 className="learn-more-text33 mt-1">{formatDistanceToNow(parseISO(notification?.created_at), { addSuffix: true, includeSeconds: false })}</h3>
                                                </div>
                                                <Link to={`/queries/${notification?.query_uuid}`}>
                                                    <p className="learn-more-link" onClick={() => {
                                                        mutate(notification?.uuid)
                                                    }}>View</p>
                                                </Link>
                                            </div>
                                        ))}
                                        <ReactPaginate
                                            previousLabel={' Previous'}
                                            previousClassName="previous-class"
                                            nextLabel={'Next '}
                                            nextClassName="next-class"
                                            pageCount={Math.ceil(data?.data?.total / resultsPerPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={'pagination'}
                                            activeClassName={'active-paginate'}
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notifications;
