import React from "react";
import DataTable from 'react-data-table-component';


const CustomDataTable = (props) => {

    return (
        <div className="table-responsive mb-[70px]">
            <DataTable
                customStyles={{
                    table: {
                        style: {
                            borderTop: "1px solid #EAEBF0",
                            borderLeft: "1px solid #EAEBF0",
                            borderRight: "1px solid #EAEBF0",
                            borderBottom: "1px solid #EAEBF0",

                            borderRadius: 8,
                            // paddingInline: "2rem",
                        },
                    },
                    pagination: {
                        style: {
                            border: "1px solid #EAEBF0",
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                        },
                    },
                    rows: {
                        style: {
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#6E7079",
                            paddingInline: "2rem",
                            "&:last-of-type": props.footer
                                ? {
                                    backgroundColor: "#F6F6F6",
                                    color: "#fff",
                                    borderBottomLeftRadius: "12px",
                                    borderBottomRightRadius: "12px",
                                }
                                : {},
                        },
                    },
                    headRow: {
                        style: {
                            fontWeight: 500,
                            color: "#2C2D33",
                            fontSize: "14px;",
                            backgroundColor: "#F6F6F6",
                            paddingInline: "2rem",
                        },
                    },
                    header: {
                        style: {
                            fontSize: "1.6rem",
                            fontWeight: 500,
                            color: "#45464E",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                            display: "none",
                        },
                    },
                    subHeader: {
                        style: {
                            paddingInline: "2rem",
                            paddingBlock: "1.5rem",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                        },
                    },
                }}
                {...props}
            />
        </div>
    );
};

export default CustomDataTable;
