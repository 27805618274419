import logo from "../assets/ConnectedLogo.png"

const VerifyEmail = () => {
    return (
        <div className="main-background">
            <div className="signup-container">
                <img className="logo-img" src={logo} alt="" />
            </div>
            <h1 className="verify-heading">Verify Your Email</h1>
            <p className="verify-heading-sub">We've sent you a verification link to your email.
                Click on the verification link to verify your email.</p>
        </div>
    )

}

export default VerifyEmail;