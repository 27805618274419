import { format, parseISO } from "date-fns";
import CustomDataTable from "../CustomDataTable";
// import resultimg from "../assets/EmptyState.svg"
import { FaRegBookmark } from "react-icons/fa6";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { archiveQuery, getAllQueries } from "../api";
import { renderErrorMessage } from "../assets/functions";
import Swal from "sweetalert2";
import { BiEditAlt } from "react-icons/bi";
import AddQuery from "../modal/AddQuery";
import Modal from "../modal/Modal";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import searchnow from "../assets/search-normal11.svg"
import { Helmet } from "react-helmet";



const AllQueryTab = ({ isAddQueryModalOpen, setIsAddQueryModalOpen }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1); //

    // const [queriesData, setQueriesData] = useState([]);

    // State for pagination
    // const [pageNumber, setPageNumber] = useState(0);
    const resultsPerPage = 10;
    // const pagesVisited = pageNumber * resultsPerPage;


    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedquery, setselectedquery] = useState(null);

    const { data: queries, refetch } = useQuery({
        queryKey: ["getAllQueries", currentPage],
        queryFn: () => getAllQueries(searchQuery, currentPage),
        suspense: true,
    });


    const { mutate } = useMutation(archiveQuery, {
        onSuccess: (data) => {

        },
        onError: (error) => renderErrorMessage(

        ),

    });


    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };


    const columns = [
        {
            name: 'Query Title',
            selector: row => row.title,
            compact: true,
            width: "300px",
            cell: (row) => (
                <Link className="cursor-pointer" to={`/queries/${row.uuid}`}>
                    {row.title}
                </Link>
            ),
        },
        {
            name: 'Tags',
            selector: (row) => row.tags.join(', '),
            compact: true,
            width: "240px",
        },
        {
            name: 'Created At',
            selector: (row) => {
                const date = parseISO(row.created_at);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            width: "180px",
        },
        {
            name: 'Deadline',
            selector: (row) => {
                const date = parseISO(row.deadline);
                return format(date, "MMM d, yyyy h:mma");
            },
            compact: true,
            width: "180px",
        },
        {
            name: 'Status',
            selector: row => row.status,
            compact: true,
            width: "120px",
        },
        {
            name: 'Pitch',
            selector: row => row.responses,
            compact: true,
            width: ""
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex gap-4">
                    <BiEditAlt className="cursor-pointer stroke-primary text-primary mr-3"
                        onClick={() => {
                            setselectedquery(row);
                            setIsEditModalOpen(true);
                        }}
                    />
                    <FaRegBookmark className="cursor-pointer stroke-primary text-primary" title="Print Movement Sheet"
                        onClick={() => {
                            Swal.fire({
                                title: "Do you want to archive this query?",
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Archive Query",
                                denyButtonText: `Cancel`
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    // Call mutate function and handle success/error within the callback
                                    mutate(row?.uuid, {
                                        onSuccess: () => {
                                            Swal.fire({
                                                icon: 'success',
                                                title: 'Query archived successfully!',
                                                showConfirmButton: false,
                                                timer: 1500
                                            });
                                            refetch()
                                        },
                                        onError: (error) => {
                                            Swal.fire({
                                                icon: 'error',
                                                title: 'Oops...',
                                                text: `Failed to archive the query: ${error.message}`,
                                            });
                                        }
                                    });
                                }
                            });

                        }}
                    />

                </div>
            ),
            width: "80px",
            compact: true,
        },
    ];



    const handleSearch = () => {
        // Perform search when user clicks on image
        refetch();
    };


    return (
        <>
            <div className="container">
                <Helmet>
                    <title>Queries</title>
                </Helmet>
                <div className="the-background-log">
                    <img src={searchnow} alt="" onClick={handleSearch} />
                    <input src="" className="input-point" placeholder="Search queries by Title"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }
                        }}
                    />
                </div>
                <div className="custom-query-table">
                    <CustomDataTable
                        columns={columns}
                        data={queries?.data?.data}
                    // pagination
                    // paginationServer={false} // Indicate that pagination is handled on the server side
                    // paginationTotalRows={13} // Total number of rows (optional if available)
                    // conditionalRowStyles={conditionalRowStyles}
                    // paginationDefaultPage={1}
                    // paginationPerPage={10}
                    // onChangePage={() => { }}
                    // onChangeRowsPerPage={() => { }}
                    />
                </div>
                <ReactPaginate
                    previousLabel={' Previous'}
                    previousClassName="previous-class"
                    nextLabel={'Next '}
                    nextClassName="next-class"
                    pageCount={queries?.data?.data ? Math.ceil(queries?.data?.total / resultsPerPage) : 0} // Assuming each page returns 10 items
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                />
            </div>
            <Modal isOpen={isAddQueryModalOpen} setIsOpen={setIsAddQueryModalOpen} title="Create a new Query" size="modal-normal">
                <AddQuery setIsOpen={setIsAddQueryModalOpen} refetch={refetch} />
            </Modal>
            <Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Query Details" size="large">
                <AddQuery myquery={selectedquery} setIsOpen={setIsEditModalOpen} refetch={refetch} />
            </Modal>
        </>
    )
}
export default AllQueryTab;
