import circleing from "../assets/info-circle.svg"
import rightContent from "../assets/right-content.svg"

const EditAccount = ({ setIsOpen, isOpen }) => {
    return (
        <>
            <div className="container">
                <h6 className="indicate-required">* Indicates required</h6>
                <form className="flex flex-col">
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="query-label">First Name *</label>
                            <input type="email" className="form-control query-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Dozie"></input>
                        </div>
                        <div className="col-lg-6">
                            <label className="query-label">Last Name *</label>
                            <input type="email" className="form-control query-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ogalabu-Maxwell"></input>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="signup-label1">Email <span><img src={circleing} alt="" /></span></label>
                                <div className="sign-up-input1 category-flex">
                                    <input type="text" className="form-controller1" id="exampleInputPassword2" placeholder="jessieobiomah@gmail.com" />
                                    <img src={rightContent} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="edit-btn-save-div">
                            <button className="edit-btn-save">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default EditAccount;