import AccountTab from "./AccountTab";
import DataPrivacyTab from "./DataPrivacyTab";
import { Helmet } from "react-helmet";

const AccountSetting = () => {
    let storedObject = localStorage.getItem('user');

    let firstname = '';
    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject?.first_name;
    } else {

    }

    return (
        <div className="container">
            <Helmet>
                <title>Account Settings</title>
            </Helmet>
            <div className="for-flex">
                <h2 className="good-greeting">Hello, {firstname}</h2>
            </div>
            <p className="my-queries-sub">Account Settings</p>
            <div className="account-container">
                <ul className="nav nav-tabsub" id="myTab" role="tablist">
                    <li className="nav-iteming">
                        <a className="nav-linking active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Account</a>
                    </li>
                    <li className="nav-iteming">
                        <a className="nav-linking" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Data & Privacy</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><AccountTab /></div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><DataPrivacyTab /></div>
                </div>
            </div>
        </div>
    )
}

export default AccountSetting;