import { ErrorMessage, Formik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { createQuery, editQuery, getAssociatedOutlets, getTags } from "../api";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import emailIcon from "../assets/Icon-right.svg"
import Select from 'react-select'




const AddQuery = ({ setIsOpen, myquery, refetch }) => {


    let storedObject = localStorage.getItem('user');

    let firstname = '';
    let lastname = '';
    let jobtitle = '';
    let userEmail = '';
    // let media = [];
    // let interests = [];


    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject.first_name;
        lastname = parsedObject.last_name;
        jobtitle = parsedObject.job_title;
        userEmail = parsedObject.email;
    } else {

    }


    const dateTimeComponents = myquery?.deadline?.split("T");
    const dateComponent = dateTimeComponents?.[0]; // Date component
    const timeComponent = dateTimeComponents?.[1].split(".")[0]; // Time component without milliseconds



    const initialValues = myquery ?
        {
            fullname: `${firstname} ${lastname}`,
            jobtitle: jobtitle,
            userEmail: userEmail,
            media_outlet: { label: myquery?.media_outlet, value: myquery?.media_outlet },
            tags: myquery?.tags?.map(tag => ({ label: tag, value: tag })) || [],
            title: myquery?.title,
            media_outlet_url: myquery?.media_outlet_url,
            brief: myquery?.brief,
            description: myquery?.description,
            date: dateComponent,
            time: timeComponent,
            id: myquery?.uuid,
        }
        :
        {
            fullname: `${firstname} ${lastname}`,
            jobtitle: jobtitle,
            userEmail: userEmail,
            media_outlet: '',
            tags: [],
            title: '',
            media_outlet_url: '',
            brief: '',
            description: '',
            date: '',
            time: '',
        };



    // const modifiedMyquery = {
    //     ...myquery,
    //     date: dateComponent,
    //     time: timeComponent,
    // };


    const signInSchema = yup.object().shape({
        title: yup.string().required("Title is required"),
        // media_outlet_url: yup.string().required("Fullname is required"),
        // brief: yup.string().required("Fullname is required"),
        // description: yup.string().required("Fullname is required"),
        // date: yup.string().required("Fullname is required"),
    });



    const { data: gettags } = useQuery({
        queryKey: ["getTags"],
        queryFn: () => getTags(),
    });

    const { data: outlet } = useQuery({
        queryKey: ["getAssociatedOutlets"],
        queryFn: () => getAssociatedOutlets(),
    });

    const options = outlet?.data?.map((option, index) => ({
        value: option.name,
        label: option.name,
    }));

    const options1 = gettags?.data?.map((option, index) => ({
        value: option?.name,
        label: option?.name,
    }));



    const { mutate, isLoading } = useMutation(myquery ? editQuery : createQuery, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            setIsOpen(false);
            refetch();
        },
        onError: (error) => {
            renderErrorMessage(error.message);
        },
    });



    return (
        <div className="container">
            <Formik
                initialValues={initialValues}
                validationSchema={signInSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const transformedTags = values.tags.map(tag => tag.value);

                    const data = {
                        ...values,
                        media_outlet: values.media_outlet.value,
                        tags: transformedTags,
                    };

                    mutate(data);  // Assuming mutate is your API call function

                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="query-label">Full Name *</label>
                                <input type="text"
                                    className="form-control query-modal-div"
                                    name="first_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fullname}
                                    disabled
                                ></input>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Job Title *</label>
                                <input type="text"
                                    className="form-control query-modal-div"
                                    name="job_title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.jobtitle}
                                    disabled
                                ></input>
                            </div>

                            <div className="col-lg-6">
                                <label for="exampleFormControlSelect1" className="query-label">Media/News outlet *</label>
                                <Select className=""
                                    name="media_outlet"
                                    value={values.media_outlet}
                                    onChange={(value) => setFieldValue("media_outlet", value)}
                                    options={options}
                                />
                                <p className="type-of-header">Type your own outlet or select from the list</p>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Media/News outlet URL *</label>
                                <input type="text" className="form-control query-input"
                                    name="media_outlet_url"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.media_outlet_url}
                                ></input>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Email</label>
                                <div className="query-modal-div">
                                    <input type="email" className="form-control my-inputing"
                                        name="userEmail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userEmail}
                                        disabled
                                    ></input>
                                    <img src={emailIcon} alt="" />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <label className="query-label">Query Title *</label>
                                <input type="text" className="form-control query-input"
                                    name="title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.title}
                                ></input>
                                <ErrorMessage name="title" component="div" className="text-red-500 text-[1rem]" />
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Brief *</label>
                                <textarea type="text" className="form-control query-input1"
                                    name="brief"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.brief}
                                ></textarea>
                                <ErrorMessage name="brief" component="div" className="text-red-500 text-[1rem]" />
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Query Description *</label>
                                <textarea type="text" className="form-control query-input1"
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                ></textarea>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Date *</label>
                                <input type="date" className="form-control query-input"
                                    name="date"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.date}
                                ></input>
                            </div>
                            <div className="col-lg-6">
                                <label className="query-label">Time *</label>
                                <input type="time" className="form-control query-input"
                                    name="time"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.time}
                                ></input>
                            </div>
                            <div className="col-lg-12">
                                <label className="query-label">Select topics that relate to this query *</label>
                                {/* <input type="text" className="form-control query-input" placeholder="Enter query tag"></input> */}
                                <Select className=""
                                    value={values.tags}
                                    onChange={(value) => setFieldValue("tags", value)}
                                    options={options1}
                                    isMulti
                                />
                            </div>
                        </div>
                        <div className="create-query-div">
                            <button type="submit" disabled={isLoading} className="create-query-btn">{myquery ? "Edit Query" : "Create Query"}</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default AddQuery;