import { getUserProfile } from "../api";
import { useQuery } from "@tanstack/react-query";

const UserInfo = ({ selectedperson, role, userRole }) => {

    const { data: searchpersonresult, refetch } = useQuery({
        queryKey: ["getUserProfile", selectedperson, role, userRole],
        queryFn: () => getUserProfile(selectedperson, role, userRole),
        enabled: false,
    });

    refetch();


    return (
        <div className="container">
            <h6 className="job-title-header1">Fullname: {searchpersonresult?.data?.first_name} {searchpersonresult?.data?.last_name}</h6>
            <h6 className="job-title-header1">Email: {searchpersonresult?.data?.email}</h6>
            <h6 className="job-title-header1">Interests: {searchpersonresult?.data?.interests.join(', ')}</h6>
            <h6 className="job-title-header1">Nationality: {searchpersonresult?.data?.country}</h6>
            <h6 className="job-title-header1">Role: {searchpersonresult?.data?.role}</h6>
            <h6 className="job-title-header1">Bio: {searchpersonresult?.data?.bio}</h6>
        </div>
    )
}

export default UserInfo;