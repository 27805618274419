import { useParams } from "react-router-dom";
import queryPerson from "../assets/query-person.png";
import paulPerson from "../assets/paul-picture.svg";
import clock from "../assets/clock.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ReplyPitch, getQueryDetails } from "../api";
import { parseISO, format, formatDistanceToNow } from 'date-fns';
import { useState } from "react";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { Formik } from "formik";
import ReactPaginate from "react-paginate";
import jpeglevel from "../assets/greenjpeg.png"
import pdflevel from "../assets/Filetypes.png"
import bluepng from "../assets/bluepng.png"
import blackjpg from "../assets/blackjpg.png";
import tealjpg from "../assets/videomp4.png";
import { Helmet } from "react-helmet";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";


const QueryDetails = () => {

    const [currentPage, setCurrentPage] = useState(1); //


    const resultsPerPage = 2;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);

    };



    // const reloadPage = () => {
    //     window.location.reload();
    // };


    const { user } = useProtectedRoutesContext()

    let firstname = '';
    let lastname = '';





    const { queryId } = useParams()

    const { data, refetch } = useQuery({
        queryKey: ["getQueryDetails", queryId, currentPage],
        queryFn: () => getQueryDetails(queryId, currentPage),
        suspense: true,
    });




    const { mutate } = useMutation(ReplyPitch, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            refetch()
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),

    });

    const handleReplySubmit = (queryId, pitchId, reply) => {
        mutate({ queryId, pitchId, replyText: reply });
    };



    return (
        <div>
            <Helmet>
                <title>Query Details</title>
            </Helmet>
            <div className="container">
                <div className="for-flex">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                </div>
                <div className="query-details-container">
                    <div className="extended-div-container">
                        <div className="">
                            <div className="extended-admin">
                                <h4 className="extended-admin-text">{data?.data?.queryDetails?.title}</h4>
                            </div>
                        </div>
                        {/* <div className="extended-mainline">
                        <div className="extended-line">
                            <div className="extended-line">
                                <h6 className="extended-header"><span> <img className="extended-img" src={calender} alt="" /></span>Extend deadline</h6>
                                <h6 className="extended-header ml-4"><span> <img className="extended-img" src={archieve} alt="" /></span>Archive</h6>
                            </div>
                        </div>
                    </div> */}
                        <h3 className="query-description1">Deadline:
                            <span className="the-deadline-zone">{data?.data?.queryDetails?.deadline ? format(parseISO(data?.data?.queryDetails?.deadline), "MMM d, yyyy h:mma") : 'N/A'}</span></h3>
                    </div>

                    <div className="person-detail">
                        {data?.data?.queryDetails?.avatar ? (<img className="extended-img-person" src={data?.data?.queryDetails?.avatar} alt="" />) : (<img className="extended-img-person" src={queryPerson} alt="" />)}

                        <h2 className="person-image">{firstname} {lastname}</h2>
                    </div>
                    <h6 className="status-status">status</h6>
                    <div className="awaiting-review">
                        <img className="place-clock" src={clock} alt="" />
                        <h6 className="awaiting-text">{data?.data?.queryDetails?.status}</h6>
                    </div>
                    <h3 className="query-description">Query Description</h3>
                    <h6 className="query-sub-description">{data?.data?.queryDetails?.description}</h6>
                    {/* <h3 className="query-description">Deadline</h3> */}
                    {/* {data?.data?.deadline ? format(parseISO(data?.data?.deadline), "MMM d, yyyy h:mma") : 'N/A'} */}
                    <h3 className="query-description">Topics</h3>
                    <p className="query-sub-description">{data?.data?.queryDetails?.tags ? data.data.queryDetails?.tags.join(', ') : 'N/A'}</p>
                    <h3 className="query-description">Media/News Outlet</h3>
                    <p className="query-sub-description">{data?.data?.queryDetails?.media_outlet}</p>
                </div>

                <h3 className="query-description mb-5">Pitch <span className="query-description">{data?.data?.queryDetails?.responses}</span></h3>


                {data?.data?.pitches?.data?.map((pitch, index) => (
                    <div className="query-details-container1">
                        <div key={index} className="response-div">
                            {pitch?.avatar ? (<img src={pitch?.avatar} alt="" />) : (<img src={paulPerson} alt="" />)}
                            <div>
                                <h3 className="paul-name">{pitch?.first_name}</h3>
                                <h6 className="paul-subname">Source</h6>
                                <h6 className="paul-subname">
                                    {/* {formatDistanceToNow(parseISO(data?.data?.queryDetails?.created_at), { addSuffix: true, includeSeconds: false })} */}
                                    {formatDistanceToNow(parseISO(pitch?.created_at), { addSuffix: true, includeSeconds: false })}
                                </h6>

                            </div>

                        </div>
                        <div className='d-flex gap-2 mt-3'>
                            {pitch?.attachments && Array.isArray(pitch.attachments) && pitch.attachments.map((file, index) => (
                                <div key={index}>

                                    <div>
                                        {(file.split('.')[(file.split('.')).length - 1]).split('/')[0] === "pdf" && (

                                            <a href={file} target="_blank" rel="noopener noreferrer" download><img className="tailwind" src={pdflevel} alt="PDF" /></a>

                                        )}
                                    </div>
                                    <div>
                                        {(file.split('.')[(file.split('.')).length - 1]).split('/')[0] === "jpeg" && (

                                            <a href={file} target="_blank" rel="noopener noreferrer" download><img className="tailwind" src={jpeglevel} alt="JPEG" /></a>

                                        )}
                                    </div>
                                    <div>
                                        {(file.split('.')[(file.split('.')).length - 1]).split('/')[0] === "jpg" && (


                                            <a href={file} target="_blank" rel="noopener noreferrer" download><img className="tailwind" src={blackjpg} alt="JPG" /></a>

                                        )}
                                    </div>
                                    <div>
                                        {(file.split('.')[(file.split('.')).length - 1]).split('/')[0] === "png" && (

                                            <a href={file} target="_blank" rel="noopener noreferrer" download><img className="tailwind" src={bluepng} alt="PNG" /></a>

                                        )}
                                    </div>
                                    <div>
                                        {(file.split('.')[(file.split('.')).length - 1]).split('/')[0] === "mp4" && (

                                            <a href={file} target="_blank" rel="noopener noreferrer" download><img className="tailwind" src={tealjpg} alt="mp4" /></a>

                                        )}
                                    </div>
                                    {/* <a href={file} target="_blank" rel="noopener noreferrer" download><img className="tailwind" src={bluepng} alt="PNG" /></a> */}
                                    {/* <div>
                                    <p className='file-name-kb'>{file.name}</p>
                                    <p className='file-size-kb'>{file.size} KB</p>
                                </div> */}
                                </div>


                            ))}
                        </div>
                        <h3 className="paul-subname1"><b>Pitch Title: {pitch?.title}</b></h3>
                        <h6 className="paul-subname1">{pitch?.description}</h6>
                        <div>
                            <div key={index}>
                                <ReplyComponent
                                    queryId={queryId}
                                    pitchId={pitch?.uuid}
                                    onReplySubmit={handleReplySubmit}
                                    replies={pitch}
                                    firstname={firstname}
                                    refetch={refetch}
                                />
                            </div>

                        </div>
                    </div>
                ))}

                <ReactPaginate
                    previousLabel={'< Prev'}
                    previousClassName="previous-class"
                    nextLabel={'Next >'}
                    nextClassName="next-class"
                    pageCount={data?.data?.pitches ? Math.ceil(data?.data?.pitches?.total / resultsPerPage) : 0}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."

                />
            </div>
        </div>

    )
}



const ReplyComponent = ({ queryId, pitchId, onReplySubmit, replies, firstname, refetch }) => {
    const [showInput, setShowInput] = useState(false);

    return (
        <div>
            {showInput ? (
                <div className="reply-container">
                    <Formik
                        initialValues={{ replyText: '' }}
                        onSubmit={(values, { resetForm }) => {
                            onReplySubmit(queryId, pitchId, values.replyText);
                            resetForm();
                            setShowInput(false);
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="input-comment-text-div">
                                    <input
                                        className="input-comment-text"
                                        type="reply"
                                        name="replyText"
                                        placeholder="Post your reply"
                                        value={values.replyText}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <button className="submit-reply" type="submit">Reply</button>
                                </div>


                            </form>
                        )}
                    </Formik>

                </div>
            ) : (
                <p className="reply" onClick={() => setShowInput(!showInput)}>Reply</p>
            )}
            <div className="ml-5">
                {replies?.replies?.map((reply, index) => (
                    <div>
                        <div key={index} className="response-div mt-5">
                            {
                                reply?.journalist_first_name && reply?.journalist_avatar !== null ?
                                    (<img src={reply?.journalist_avatar} alt="" />) :
                                    (
                                        reply?.journalist_first_name ?
                                            (<img src={paulPerson} alt="" />) :
                                            (
                                                reply?.source_first_name && reply?.source_avatar !== null ?
                                                    (<img src={reply?.source_avatar} alt="" />) :
                                                    (<img src={paulPerson} alt="" />)
                                            )
                                    )
                            }

                            <div>
                                {
                                    reply?.journalist_first_name !== null ?
                                        (<h3 className="paul-name">{reply?.journalist_first_name}</h3>) :
                                        (
                                            (<h3 className="paul-name">{reply?.source_first_name}</h3>)
                                        )
                                }
                                <h3 className="paul-name">{reply?.journalist_first_name ? (<h6 className="paul-subnames">Journalist</h6>) : (<h6 className="paul-subnames">Source</h6>)}</h3>
                                <h6 className="paul-subname">{formatDistanceToNow(parseISO(reply?.created_at), { addSuffix: true, includeSeconds: false })}</h6>

                            </div>
                        </div>
                        <h6 className="paul-subname1">{reply?.reply}</h6>
                    </div>


                ))}

            </div>

        </div>
    );
};

export default QueryDetails;