import { useMutation } from "@tanstack/react-query";
import logo from "../assets/ConnectedLogo.png"
import { signUp } from "../api";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Helmet } from "react-helmet";



const SignUp = () => {
    const navigate = useNavigate();

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
    };

    const { mutate, isLoading } = useMutation(signUp, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            navigate(`/verify`);
        },
        onError: (error) => renderErrorMessage(
            error.message
        ),
    });



    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email("Please provide a valid email")
            .required("Please provide a valid email"),
        first_name: yup.string().required("Please enter your Firstname"),
        last_name: yup.string().required("Please enter your Lastname"),
    });




    return (
        <div>
            <Helmet>
                <title>Sign Up</title>
            </Helmet>
            <div className="main-background">
                <div className="signup-container">
                    <img className="logo-img" src={logo} alt="" />
                </div>
                <div className="container">
                    <div className="sign-up-form">
                        <h2 className="sign-up-form-text1">Sign Up</h2>
                        <p className="sign-up-form-text-sub">Let’s start your onboarding</p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => mutate(values)}
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label className="signup-label">First Name</label>
                                        <input type="text" className=" sign-up-input"
                                            placeholder="Enter Firstname"
                                            name="first_name"
                                            value={values.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage
                                            name="first_name"
                                            component="div"
                                            className="text-red-500  text-[1.4rem]"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="signup-label">Last Name</label>
                                        <input type="text" className=" sign-up-input" placeholder="Enter Lastname"
                                            name="last_name"
                                            value={values.last_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage
                                            name="last_name"
                                            component="div"
                                            className="text-red-500  text-[1.4rem]"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="signup-label">Email</label>
                                        <input type="email" className=" sign-up-input" placeholder="Enter Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="text-red-500  text-[1.4rem]"
                                        />
                                    </div>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="signup-label" for="exampleCheck1">I agree to Connected Newsroom’s <a className="terms-text" href="google.com">Terms of service</a> & <a className="terms-text" href="google.com">Privacy Policy </a></label>
                                    </div>
                                    <button type="submit" className=" sign-up-btn" disabled={isLoading}>Get Started</button>
                                    <p className="sign-up-form-text-sub-small">Already have an account?<a className="sign-up-form-text-sub-small-login" href="/login"> Log in</a></p>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SignUp;