import logo from "../assets/ConnectedLogo.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import { NewCredentials } from "../api";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const SetNewPassword = () => {
    const navigate = useNavigate();
    const urlToken = localStorage.getItem('urlToken');


    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);


    const signInSchema = yup.object().shape({
        password: yup
            .string()
            .required("Password is required")
            .min(8, "Password must be 8 characters long")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a symbol"),
        password_confirmation: yup
            .string()
            .required("Confirm your password")
            .oneOf([yup.ref("password"), null], "Passwords don't match"),
    });

    const storedEmail = localStorage.getItem('resetemail');
    const initialValues = {
        password: "",
        password_confirmation: "",
        // token: urlToken,
        email: storedEmail,
    };

    const { mutate, isLoading } = useMutation(NewCredentials, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);
            navigate("/login")
        },
        onError: (error) => renderErrorMessage(error),
    });

    return (
        <div className="main-background">
            <div className="signup-container">
                <img className="logo-img" src={logo} alt="" />
            </div>
            <div className="container">
                <div className="sign-up-form">
                    <div className="input-container">
                        <h2 className="sign-up-form-text1">Set Password</h2>
                    </div>
                    {/* <p className="sign-up-form-text-sub">Create your account</p> */}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={signInSchema}
                        onSubmit={(values) => mutate(values)}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mt-5">
                                    <label className="signup-label">Password</label>
                                    <div className="has-pwd-icon">
                                        <input
                                            name="password"
                                            className=" sign-up-input"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={visible ? "text" : "password"}
                                        />
                                        {visible ? (
                                            <BsEye
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <BsEyeSlash
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="signup-label">Confirm Password</label>
                                    <div className="has-pwd-icon">
                                        <input
                                            name="password_confirmation"
                                            className=" sign-up-input"
                                            value={values.password_confirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type={visible1 ? "text" : "password"}
                                        />
                                        {visible1 ? (
                                            <BsEye
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible1(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <BsEyeSlash
                                                className="view-pwd"
                                                size={20}
                                                onClick={() =>
                                                    setVisible1(
                                                        (prevState) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <ErrorMessage
                                        name="password_confirmation"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </div>
                                <button type="submit" className=" sign-up-btn" disabled={isLoading}>Set Password</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;
